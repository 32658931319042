import React from 'react'
import {
  CCol,
  CRow,
  CInput,
  CButton,
  CForm
} from '@coreui/react'

const TableHeader = props => {
  const {
    name,
    text,
    isSearchDisplay = true,
    ...rest
  } = props

  const exportView = props.exportView == true ? "" : "d-none";
  return (
    <CRow>
      <CCol sm="6">
        <h5>
          <strong>{props.text}</strong>
        </h5>
      </CCol>
      {isSearchDisplay ? <CCol sm="6">
        <CForm className="justify-content-end" inline onSubmit={(event) => event.preventDefault()}>
          <CInput
            name="keyword"
            className="mr-sm-2"
            placeholder={props.placeholder ?? "Search By name"}
            onChange={props.setValue}
            //onKeyDown={(e) => (e.key === 'Enter') ? setKeyword(e.target.value) : ''}
            value={props.searchValue}
          />
          <CButton
            color="info"
            className="my-2 my-sm-0 mr-2"
            onClick={() => props.setKeyword(props.searchValue)}
          >
            <i class="cil-search"></i> Search
          </CButton>
          <CButton
            color="secondary"
            className="my-2 my-sm-0 mr-2"
            onClick={() => {
              props.setKeyword("");
              props.setSearchValue("");
            }}
            disabled={!props.searchValue}
          >
            <i class="cil-sync"></i> Reset
          </CButton>
          <CButton
            color="info"
            className={`my-2 my-sm-0 ${exportView}`}
            onClick={() => props.setKeyword(props.exportHandle)}
          >
            <i class="cil-cloud-download"></i> Export{" "}
          </CButton>
        </CForm>
      </CCol> : ''}
    </CRow>
  );
}

export default React.memo(TableHeader)
