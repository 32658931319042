import { settingConstants } from '../constants';

const initialState = {};
export function setting(state = initialState, action) {
  switch (action.type) {
    case settingConstants.ADD_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case settingConstants.ADD_SUCCESS:
      return {
        loggedIn: true,
        token: action.user && action.user.token,
        user: action.user
      };
    case settingConstants.ADD_FAILURE:
      return {};
    case settingConstants.DELETE_REQUEST:
      return {
        submitted: true,
      };
    case settingConstants.DELETE_SUCCESS:
      return {
        deleted: true
      };
    case settingConstants.DELETE_FAILURE:
      return {};
    case settingConstants.LIST_SUCCESS:
      return {
        list: action.payload && action.payload.streams,
      };
    default:
      return state
  }
}