export * from "./user.service";
export * from "./commission.service";
export * from "./category.service";
export * from "./gallery.service";
export * from "./setting.service";
export * from "./email.service";
export * from "./uvRep.service";
export * from "./chat.service";
export * from "./uvNews.service";
export * from "./uvStore.service";
export * from "./home.service";
export * from "./subscriber.service";
export * from "./faq.service";
export * from "./review.service";
export * from "./liveGroupChat.service";
export * from "./personalAppearnce.service";
export * from "./color.service";
export * from "./industry.service";
export * from "./marketplace.service";
export * from "./tos.service";
export * from "./directMesssage.service";